<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAccounts(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <agent-overview
      :agent="agent"
    />
    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          lg="3"
        >
          <b-input-group class="mb-1-sm">
            <b-form-input
              v-model="filter.account"
              :placeholder="$t('account')"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="loadData(1)"
                v-text="$t('search')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          v-if="userData.is_sub_account === 0 || (userData.is_sub_account === 1 && userData.authority === 'Edit')"
          cols="12"
          md="7"
          lg="9"
        >
          <div
            v-if="agent.role === 'admin' || agent.role === 'company' || agent.role === 'agent'"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-for="(actType, index) in accountType"
              :key="`actType_${index}`"
              variant="primary"
              class="ml-1"
              @click="createAccountModal(actType.value)"
            >
              {{ $t(`create_${actType.value}`) }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
    >
      <div class="card">
        <div class="card-body">
          <h4
            class="card-title"
            v-text="agentTitle"
          />
          <p
            v-if="agent.role === 'partner'"
            style="position: absolute;right: 20px;top: 20px;"
          >
            <span v-text="$t('total_member_balance')" />: <span v-text="numberFormatter(meta.report.memberTotalBalance)" />
          </p>
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr v-if="agent.role === 'partner'">
                      <th class="vgt-left-align">
                        <span v-text="$t('idx')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('company')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('agent')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('partner_id')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('partner_name')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('user_id')" />
                      </th>
                      <th class="vgt-center-align">
                        <span v-text="$t('operation')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('balance')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('created_at')" />
                      </th>
                    </tr>
                    <tr v-else>
                      <th
                        v-if="displayOperation == true"
                        class="vgt-left-align vgt-center-align"
                      >
                        <span v-text="$t('operation')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('account')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('name')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('dl_count')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('phone')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('account_status')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('account_type')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('own_balance')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('create_time')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('last_login')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in accounts"
                      :key="item.id"
                    >
                      <!-- IDX -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.idx" />
                      </td>
                      <!-- Company -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.company" />
                      </td>
                      <!-- Agent -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.agent" />
                      </td>
                      <!-- Partner Id -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.partner_id" />
                      </td>
                      <!-- Partner Name -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.partner_name" />
                      </td>
                      <!-- User ID -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.user_id" />
                      </td>
                      <!-- Operation -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-center-align"
                      >
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          style="margin-right: 2px;"
                          @click="showTransfer(item, 'transfer_to_child', $t('transfer_to_child'))"
                          v-text="$t('deposit')"
                        />
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          style="margin-right: 2px;"
                          @click="showTransfer(item, 'transfer_from_child', $t('transfer_from_child'))"
                          v-text="$t('withdraw')"
                        />
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          style="margin-right: 2px;"
                          @click="viewTransactionList(item.memberId)"
                          v-text="$t('list')"
                        />
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="editMemberLimit(item)"
                          v-text="$t('limit')"
                        />
                      </td>
                      <!-- Balance -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="numberFormatter(item.balance)" />
                      </td>
                      <!-- Created At -->
                      <td
                        v-if="agent.role === 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.created_at" />
                      </td>
                      <!-- Operation -->
                      <td
                        v-if="displayOperation == true && agent.role !== 'partner'"
                        class="vgt-left-align vgt-center-align"
                      >
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="showOperation(item)"
                          v-text="$t('operation')"
                        />
                      </td>
                      <!-- Account -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <a
                          v-if="item.account_type !== 'member'"
                          href="#"
                          class="align-middle ml-50"
                          @click="loadAccounts(item.id)"
                        >
                          <feather-icon
                            :icon="`UserIcon`"
                            style="margin-right:2px;"
                          />
                          <span v-text="item.account" />
                        </a>
                        <span
                          v-else
                          v-text="item.account"
                        />
                      </td>
                      <!-- Name -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.nickname" />
                      </td>
                      <!-- Downline Count -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="item.dl_count" />
                      </td>
                      <!-- Phone -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="item.phone" />
                      </td>
                      <!-- Account Status -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <div style="position: relative;">
                          <span
                            v-if="item.account_status === true"
                            style="position: absolute;right: 65px;"
                            v-text="$t('enable')"
                          />
                          <span
                            v-else
                            style="position: absolute;right: 65px;"
                            v-text="$t('disable')"
                          />
                          <b-form-checkbox
                            v-if="userData.is_sub_account === 0 || (userData.is_sub_account === 1 && userData.authority === 'Edit')"
                            v-model="item.account_status"
                            class="custom-control-dark"
                            name="check-button"
                            :disabled="!userData.can_edit"
                            switch
                            @change="changeStatus($event, item)"
                          />
                        </div>
                      </td>
                      <!-- Account Type -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="stringFormatter(item.account_type)" />
                      </td>
                      <!-- Own Balance -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="numberFormatter(item.balance)" />
                      </td>
                      <!-- Created Time -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.create_time" />
                      </td>
                      <!-- Last Login -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-left-align"
                      >
                        <span v-text="item.last_login" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>

    <!-- Create Modal -->
    <b-modal
      ref="create-account-modal"
      size="lg"
      :title="$t('create_child_agent')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="createAccountHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('account_type')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.account_type"
                :options="accountType"
                name="account-type-radio-inline"
                style="padding-top: 8px;"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="formData.account_type === 'partner'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_multi_bet')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left; margin-top: 6px"
            >
              <b-form-checkbox
                v-model="formData.can_multi_bet"
                name="can-multi-bet"
                value="true"
                unchecked-value="false"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <div v-if="formData.account_type !== null">
          <b-form-group
            v-if="(formData.account_type === 'agent' && agent.role === 'company') || (formData.account_type === 'agent' && (agent.role === 'agent' && agent.can_create_agent === 'Yes'))"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('can_create_agent')"
            style="text-align: right"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative; text-align:left;"
              >
                <b-form-radio-group
                  v-model="formData.can_create_agent"
                  :options="canCreateAgentOptions"
                  name="can-create-agent"
                  style="padding-top: 8px;"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('account')"
            label-for="create-account-input-account"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-account"
                  v-model="formData.account"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('create_modify_user_account_field')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password')"
            label-for="create-account-input-password"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-password"
                  v-model="formData.password"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password_confirm')"
            label-for="create-account-input-password-confirm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-password-confirm"
                  v-model="formData.password_confirmation"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('name')"
            label-for="create-account-input-nickname"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-nickname"
                  v-model="formData.nickname"
                  :maxlength="maxNickNameCount"
                  autocomplete="off"
                  @keyup="getNickNameRemainingCount()"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('person_in_charge')"
            label-for="create-account-input-person-in-charge"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-person-in-charge"
                  v-model="formData.person_in_charge"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('contact_details')"
            label-for="create-account-input-telephone-number"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-telephone-number"
                  v-model="formData.contact_number"
                  type="number"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="create-account-input-email-address"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-email-address"
                  v-model="formData.email_address"
                  autocomplete="off"
                  type="email"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('white_list_ips')"
            label-for="create-account-input-white-list-ips"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-white-list-ips"
                  v-model="formData.white_list_ips"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="create-account-input-telegram-id"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-telegram-id"
                  v-model="formData.telegram_id"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('rate')"
            label-for="create-account-input-rate"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-rate"
                  v-model="formData.rate"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('memo')"
            label-for="create-account-input-memo"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <textarea
                  id="create-account-input-memo"
                  v-model="formData.memo"
                  class="form-control"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('logout_url')"
            label-for="create-account-input-logout-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-logout-url"
                  v-model="formData.logout_url"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('seamless_type')"
            style="text-align: right"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative; text-align:left;"
              >
                <b-form-radio-group
                  v-model="formData.seamless_type"
                  :options="seamlessType"
                  name="seamless-type-radio-inline"
                  style="padding-top: 8px;"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'half'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_url')"
            label-for="create-account-input-callback-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-callback-url"
                  v-model="formData.callback_url"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_balance')"
            label-for="create-account-input-callback-balance"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-callback-balance"
                  v-model="formData.callback_balance"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_bets')"
            label-for="create-account-input-callback-bets"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-callback-bets"
                  v-model="formData.callback_bets"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_results')"
            label-for="create-account-input-callback-results"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-callback-results"
                  v-model="formData.callback_results"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_cancel')"
            label-for="create-account-input-callback-cancel"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="create-account-input-callback-cancel"
                  v-model="formData.callback_cancel"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="createAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Partner Limit Modal -->
    <b-modal
      ref="edit-partner-limit-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_partner_limits')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editPartnerLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('min')" />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('max')" />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_slash_banker')"
          label-for="edit-account-input-partner-limit-player-banker-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-player-banker-min"
                v-model="partnerLimitFormData.player_banker_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-player-banker-max"
                v-model="partnerLimitFormData.player_banker_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('tie')"
          label-for="edit-account-input-partner-limit-tie-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-tie-min"
                v-model="partnerLimitFormData.tie_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-tie-max"
                v-model="partnerLimitFormData.tie_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('p_pair_and_b_pair')"
          label-for="edit-account-input-partner-limit-b-pair-and-p-pair-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-b-pair-and-p-pair-min"
                v-model="partnerLimitFormData.p_pair_and_b_pair_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-partner-limit-b-pair-and-p-pair-max"
                v-model="partnerLimitFormData.p_pair_and_b_pair_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editPartnerLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editPartnerLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Member Limit Modal -->
    <b-modal
      ref="edit-member-limit-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_member_limits')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editMemberLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('min')" />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <span v-text="$t('max')" />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('player_slash_banker')"
          label-for="edit-account-input-member-limit-player-banker-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-player-banker-min"
                v-model="memberLimitFormData.player_banker_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-player-banker-max"
                v-model="memberLimitFormData.player_banker_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('tie')"
          label-for="edit-account-input-member-limit-tie-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-tie-min"
                v-model="memberLimitFormData.tie_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-tie-max"
                v-model="memberLimitFormData.tie_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('p_pair_and_b_pair')"
          label-for="edit-account-input-member-limit-b-pair-and-p-pair-min"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-b-pair-and-p-pair-min"
                v-model="memberLimitFormData.p_pair_and_b_pair_min"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('min')"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="6"
            >
              <b-form-input
                id="edit-account-input-member-limit-b-pair-and-p-pair-max"
                v-model="memberLimitFormData.p_pair_and_b_pair_max"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('max')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editMemberLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editMemberLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      ref="edit-account-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_account')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editAccountHandleSubmit"
      >
        <b-row v-if="formData.account_type === 'partner'">
          <b-col
            cols="12"
            md="12"
            xl="12"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="formData.api_key"
              :statistic-title="$t('api_key')"
            />
          </b-col>
        </b-row>
        <b-form-group
          v-if="formData.account_type === 'partner'"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_multi_bet')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left; margin-top: 6px"
            >
              <b-form-checkbox
                v-model="formData.can_multi_bet"
                name="can-multi-bet"
                value="true"
                unchecked-value="false"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="(formData.account_type === 'agent' && agent.role === 'company') || (formData.account_type === 'agent' && (agent.role === 'agent' && agent.can_create_agent === 'Yes'))"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('can_create_agent')"
          style="text-align: right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
              style="position:relative; text-align:left;"
            >
              <b-form-radio-group
                v-model="formData.can_create_agent"
                :options="canCreateAgentOptions"
                name="can-create-agent"
                style="padding-top: 8px;"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <div v-if="userData.role === 'admin'">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('account')"
            label-for="edit-account-input-account"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-account"
                  v-model="formData.account"
                  autocomplete="off"
                  maxlength="20"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('create_modify_user_account_field')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password')"
            label-for="edit-account-input-password"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password"
                  v-model="formData.password"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password_confirm')"
            label-for="edit-account-input-password-confirm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password-confirm"
                  v-model="formData.password_confirmation"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('name')"
            label-for="edit-account-input-nickname"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-nickname"
                  v-model="formData.nickname"
                  :maxlength="maxNickNameCount"
                  autocomplete="off"
                  @keyup="getNickNameRemainingCount()"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('person_in_charge')"
            label-for="edit-account-input-person-in-charge"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-person-in-charge"
                  v-model="formData.person_in_charge"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('contact_details')"
            label-for="edit-account-input-contact-details"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative"
              >
                <b-form-input
                  id="edit-account-input-contact-details"
                  v-model="formData.contact_number"
                  type="number"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                v-if="formData.account_type === 'partner'"
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-email-address"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-email-address"
                  v-model="formData.email_address"
                  autocomplete="off"
                  type="email"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('white_list_ips')"
            label-for="edit-account-input-white-list-ips"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-white-list-ips"
                  v-model="formData.white_list_ips"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-telegram-id"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-telegram-id"
                  v-model="formData.telegram_id"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('rate')"
            label-for="edit-account-input-rate"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-rate"
                  v-model="formData.rate"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('memo')"
            label-for="edit-account-input-memo"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <textarea
                  id="edit-account-input-memo"
                  v-model="formData.memo"
                  class="form-control"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('logout_url')"
            label-for="edit-account-input-logout-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-logout-url"
                  v-model="formData.logout_url"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('valid_url')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('seamless_type')"
            style="text-align: right"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative; text-align:left;"
              >
                <b-form-radio-group
                  v-model="formData.seamless_type"
                  :options="seamlessType"
                  name="edit-seamless-type-radio-inline"
                  style="padding-top: 8px;"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'half'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_url')"
            label-for="edit-account-input-callback-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-url"
                  v-model="formData.callback_url"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_balance')"
            label-for="edit-account-input-callback-balance"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-balance"
                  v-model="formData.callback_balance"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_bets')"
            label-for="edit-account-input-callback-bets"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-bets"
                  v-model="formData.callback_bets"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_results')"
            label-for="edit-account-input-callback-results"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-results"
                  v-model="formData.callback_results"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_cancel')"
            label-for="edit-account-input-callback-cancel"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-cancel"
                  v-model="formData.callback_cancel"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
        <div v-else-if="userData.role === 'company'">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('account')"
            label-for="edit-account-input-account"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-account"
                  v-model="formData.account"
                  autocomplete="off"
                  maxlength="20"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('create_modify_user_account_field')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password')"
            label-for="edit-account-input-password"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password"
                  v-model="formData.password"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password_confirm')"
            label-for="edit-account-input-password-confirm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password-confirm"
                  v-model="formData.password_confirmation"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('name')"
            label-for="edit-account-input-nickname"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-nickname"
                  v-model="formData.nickname"
                  :maxlength="maxNickNameCount"
                  autocomplete="off"
                  @keyup="getNickNameRemainingCount()"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('person_in_charge')"
            label-for="edit-account-input-person-in-charge"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-person-in-charge"
                  v-model="formData.person_in_charge"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('contact_details')"
            label-for="edit-account-input-country-code"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative"
              >
                <b-form-input
                  id="edit-account-input-telephone-number"
                  v-model="formData.contact_number"
                  type="number"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-email-address"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-email-address"
                  v-model="formData.email_address"
                  autocomplete="off"
                  type="email"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('white_list_ips')"
            label-for="edit-account-input-white-list-ips"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-white-list-ips"
                  v-model="formData.white_list_ips"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-telegram-id"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-telegram-id"
                  v-model="formData.telegram_id"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('rate')"
            label-for="edit-account-input-rate"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-rate"
                  v-model="formData.rate"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('memo')"
            label-for="edit-account-input-memo"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <textarea
                  id="edit-account-input-memo"
                  v-model="formData.memo"
                  class="form-control"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('logout_url')"
            label-for="edit-account-input-logout-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-logout-url"
                  v-model="formData.logout_url"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('seamless_type')"
            style="text-align: right"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative; text-align:left;"
              >
                <b-form-radio-group
                  v-model="formData.seamless_type"
                  :options="seamlessType"
                  name="edit-seamless-type-radio-inline"
                  style="padding-top: 8px;"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'half'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_url')"
            label-for="edit-account-input-callback-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-url"
                  v-model="formData.callback_url"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_balance')"
            label-for="edit-account-input-callback-balance"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-balance"
                  v-model="formData.callback_balance"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_bets')"
            label-for="edit-account-input-callback-bets"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-bets"
                  v-model="formData.callback_bets"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_results')"
            label-for="edit-account-input-callback-results"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-results"
                  v-model="formData.callback_results"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_cancel')"
            label-for="edit-account-input-callback-cancel"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-cancel"
                  v-model="formData.callback_cancel"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
        <div v-else-if="userData.role === 'agent'">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('account')"
            label-for="edit-account-input-account"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-account"
                  v-model="formData.account"
                  autocomplete="off"
                  maxlength="20"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('create_modify_user_account_field')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password')"
            label-for="edit-account-input-password"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password"
                  v-model="formData.password"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('password_confirm')"
            label-for="edit-account-input-password-confirm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-password-confirm"
                  v-model="formData.password_confirmation"
                  type="password"
                  autocomplete="off"
                  maxlength="20"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span class="create-child-requirements">
                  <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                  <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('name')"
            label-for="edit-account-input-nickname"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-nickname"
                  v-model="formData.nickname"
                  :maxlength="maxNickNameCount"
                  autocomplete="off"
                  @keyup="getNickNameRemainingCount()"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('person_in_charge')"
            label-for="edit-account-input-person-in-charge"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-person-in-charge"
                  v-model="formData.person_in_charge"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            :label="$t('contact_details')"
            label-for="edit-account-input-country-code"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative"
              >
                <b-form-input
                  v-if="formData.account_type === 'partner'"
                  id="edit-account-input-telephone-number"
                  v-model="formData.contact_number"
                  type="number"
                  autocomplete="off"
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-email-address"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-email-address"
                  v-model="formData.email_address"
                  autocomplete="off"
                  type="email"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('white_list_ips')"
            label-for="edit-account-input-white-list-ips"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-white-list-ips"
                  v-model="formData.white_list_ips"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('valid_ipv4_address')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('telegram_id')"
            label-for="edit-account-input-telegram-id"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-telegram-id"
                  v-model="formData.telegram_id"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('rate')"
            label-for="edit-account-input-rate"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-rate"
                  v-model="formData.rate"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'company' || formData.account_type === 'agent'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('memo')"
            label-for="edit-account-input-memo"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <textarea
                  id="edit-account-input-memo"
                  v-model="formData.memo"
                  class="form-control"
                  autocomplete="off"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('logout_url')"
            label-for="edit-account-input-logout-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-logout-url"
                  v-model="formData.logout_url"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('valid_url')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('seamless_type')"
            style="text-align: right"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
                style="position:relative; text-align:left;"
              >
                <b-form-radio-group
                  v-model="formData.seamless_type"
                  :options="seamlessType"
                  name="edit-seamless-type-radio-inline"
                  style="padding-top: 8px;"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              />
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'half'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_url')"
            label-for="edit-account-input-callback-url"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-url"
                  v-model="formData.callback_url"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_balance')"
            label-for="edit-account-input-callback-balance"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-balance"
                  v-model="formData.callback_balance"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_bets')"
            label-for="edit-account-input-callback-bets"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-bets"
                  v-model="formData.callback_bets"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_results')"
            label-for="edit-account-input-callback-results"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-results"
                  v-model="formData.callback_results"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="formData.account_type === 'partner' && formData.seamless_type === 'full'"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('callback_cancel')"
            label-for="edit-account-input-callback-cancel"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
                xl="7"
              >
                <b-form-input
                  id="edit-account-input-callback-cancel"
                  v-model="formData.callback_cancel"
                  autocomplete="off"
                  disabled
                />
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="5"
              >
                <span
                  class="create-child-requirements"
                  style="line-height: 38px;"
                >
                  <span v-text="$t('required')" />
                </span>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Operation Modal -->
    <b-modal
      ref="operation-modal"
      :title="$t('agent_operation')"
      ok-title="Close"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row v-if="selectedChildAgent.account_type !== 'member'">
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="agent.username"
            :statistic-title="$t('agent')"
          />
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="selectedChildAgent.account"
            :statistic-title="$t('child_agent')"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="selectedChildAgent.account_type !== 'member'"
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editAccount(selectedChildAgent.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_account')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showLogs()"
          >
            <feather-icon
              icon="MonitorIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('log')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_to_child', $t('transfer_to_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_to_child')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_from_child', $t('transfer_from_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_from_child')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="selectedChildAgent.account_type === 'partner' || selectedChildAgent.account_type === 'member'"
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-if="selectedChildAgent.account_type === 'partner'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editPartnerLimit(selectedChildAgent.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_partner_limits')"
            />
          </b-button>
          <b-button
            v-if="selectedChildAgent.account_type === 'member'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editMemberLimit(selectedChildAgent)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_member_limits')"
            />
          </b-button>
        </b-col>
        <b-col
          v-if="selectedChildAgent.account_type === 'partner'"
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-if="selectedChildAgent.account_type === 'partner'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showPartnerTableLimit()"
          >
            <feather-icon
              icon="MonitorIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('table_limit')"
            />
          </b-button>
        </b-col>
      </b-row>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="operationHandleCancel"
              v-text="$t('close')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Transfer Modal -->
    <b-modal
      ref="transfer-modal"
      size="lg"
      :title="transferFormTitle"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="transfer-modal"
        @submit.stop.prevent="transferHandleSubmit"
      >
        <b-card>
          <b-row style="border: 2px solid rgb(0 0 0);padding: 7px;border-radius: 5px;color: black;margin: 0px 0px;">
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left font-weight-bold"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('agent')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${agent.username}`"
              />
            </b-col>
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('balance')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${Number(agent.balance).toLocaleString()}`"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row style="border: 2px solid rgb(0 0 0);padding: 7px;border-radius: 5px;color: black;margin: 0px 0px;">
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left font-weight-bold"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('child_agent')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${selectedChildAgent.account}`"
              />
            </b-col>
            <b-col
              cols="6"
              md="6"
              xl="6"
              class="text-left"
            >
              <span
                style="font-weight: bold; font-size: 19px;"
                v-text="$t('balance')"
              />
              <span
                style="font-size: 19px;"
                v-text="` - ${Number(selectedChildAgent.balance).toLocaleString()}`"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('transfer_amount')"
          label-for="transfer-input-amount"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="transfer-input-amount"
                v-model="transferFormData.amount"
                type="text"
                autocomplete="off"
                @keyup.stop.native="changeAmount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('memo')"
          label-for="transfer-input-memo"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-textarea
                id="transfer-input-memo"
                v-model="transferFormData.memo"
                rows="3"
                :maxlength="maxMemoCount"
                @keyup="getMemoRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
              style="text-align: left;"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ memoRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="transferHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="transferHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Logs -->
    <b-modal
      ref="logs-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('log')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <!-- Filter -->
        <b-card
          footer-tag="footer"
          class="mb-1 card-filter-container"
          style="width: 100%"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_from')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logs.filter.dateFrom"
                  :max="logs.filter.dateTo"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_from')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logs.filter.timeFrom"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_to')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logs.filter.dateTo"
                  :min="logs.filter.dateFrom"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_to')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logs.filter.timeTo"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="card-filter-item mt-1"
            >
              <b-button
                variant="primary float-right"
                @click="loadLogData(1)"
                v-text="$t('search')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="logs.loading"
          opacity="0.80"
          rounded="sm"
          style="width: 100%"
        >
          <b-card>
            <div class="card">
              <div class="card-body">
                <div class="vgt-wrap">
                  <div class="vgt-inner-wrap">
                    <div class="vgt-responsive game-history-table">
                      <table class="vgt-table bordered">
                        <thead>
                          <tr>
                            <th class="vgt-left-align">
                              <span v-text="$t('operator_account')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('type')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('related_account')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('time')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('ip')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('memo')" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in logs.records"
                            :key="item.id"
                          >
                            <!-- Operator Account -->
                            <td class="vgt-left-align">
                              <span v-text="item.operator_account" />
                            </td>
                            <!-- Type -->
                            <td class="vgt-left-align">
                              <span v-text="item.type" />
                            </td>
                            <!-- Related Account -->
                            <td class="vgt-left-align">
                              <span v-text="item.related_account" />
                            </td>
                            <!-- Time -->
                            <td class="vgt-left-align">
                              <span v-text="item.time" />
                            </td>
                            <!-- IP -->
                            <td class="vgt-left-align">
                              <span v-text="item.ip" />
                            </td>
                            <!-- Memo -->
                            <td class="vgt-left-align">
                              <span v-text="item.memo" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <custom-pagination
                  v-model="logs.filter.page"
                  :total-items="logs.totalItems"
                  :per-page="logs.perPage"
                  :current-page="logs.filter.page"
                  @page-changed="loadLogData"
                  @change-item-per-page="changeLogItemPerPage"
                />
              </div>
            </div>
          </b-card>
          <br>
        </b-overlay>
      </b-row>
    </b-modal>

    <!-- View Transaction List Modal -->
    <b-modal
      ref="view-transaction-list-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('transactions')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <b-col
          cols="12"
          md="12"
          xl="12"
        >
          <b-overlay
            :show="transactionList.loading"
            opacity="0.80"
            rounded="sm"
          >
            <div class="card">
              <div class="card-body">
                <div class="vgt-wrap">
                  <div class="vgt-inner-wrap">
                    <div class="vgt-responsive">
                      <table class="vgt-table bordered">
                        <thead>
                          <tr>
                            <th class="vgt-left-align">
                              <span v-text="$t('partner_name')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('account')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('time')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('shoe_id')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('round_id')" />
                            </th>
                            <th class="vgt-left-align sortable">
                              <span v-text="$t('transaction_type')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('before')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('amount')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('after')" />
                            </th>
                            <th class="vgt-left-align">
                              <span v-text="$t('memo')" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in transactionList.records"
                            :key="item.id"
                          >
                            <!-- Partner Name -->
                            <td class="vgt-left-align">
                              <span v-text="item.partner_name" />
                            </td>
                            <!-- Account -->
                            <td class="vgt-left-align">
                              <span v-text="item.account" />
                            </td>
                            <!-- Time -->
                            <td class="vgt-left-align">
                              <span v-text="item.time" />
                            </td>
                            <!-- Shoe ID -->
                            <td class="vgt-left-align">
                              <span class="game-table-id">{{ item.game_table_id }}</span>
                              <span class="game-shoe-id">{{ item.game_shoe_id }}</span>
                              <span class="game-round-id">{{ item.game_round_id }}</span>
                            </td>
                            <!-- Round ID -->
                            <td class="vgt-left-align">
                              <span v-text="item.round_id" />
                            </td>
                            <!-- Transaction Type -->
                            <td class="vgt-left-align">
                              <span v-text="item.transaction_type" />
                            </td>
                            <!-- Before -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.before"
                              />
                            </td>
                            <!-- Amount -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.amount"
                              />
                            </td>
                            <!-- After -->
                            <td class="vgt-left-align">
                              <span
                                class="align-middle ml-50"
                                v-text="item.after"
                              />
                            </td>
                            <!-- Memo -->
                            <td class="vgt-left-align">
                              <span v-text="item.memo" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <custom-pagination
                  v-model="transactionList.filter.page"
                  :total-items="transactionList.totalItems"
                  :per-page="transactionList.perPage"
                  :current-page="transactionList.filter.page"
                  @page-changed="loadTransactionList"
                  @change-item-per-page="changeTransactionItemPerPage"
                />
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Partner Table Limit -->
    <b-modal
      ref="partner-table-limit-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('table_limit')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <div>
        <b-row class="pb-2">
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="card-filter-item"
          >
            <b-button
              variant="primary float-right"
              @click="addTableLimitModal"
              v-text="$t('add_table_limit')"
            />
          </b-col>
        </b-row>
        <b-overlay
          :show="partnerTableLimit.loading"
          opacity="0.80"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <div class="vgt-wrap">
                <div class="vgt-inner-wrap">
                  <div class="vgt-responsive game-history-table">
                    <table class="vgt-table bordered">
                      <thead>
                        <tr>
                          <th class="vgt-left-align">
                            <span v-text="$t('operation')" />
                          </th>
                          <th class="vgt-left-align">
                            <span v-text="$t('table_name')" />
                          </th>
                          <th class="vgt-left-align">
                            <span v-text="$t('limit')" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in partnerTableLimit.items"
                          :key="item.id"
                        >
                          <td class="vgt-left-align">
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              class="mr-1"
                              @click="editTableLimitModal(item)"
                              v-text="$t('edit')"
                            />
                            <b-button
                              variant="outline-danger"
                              size="sm"
                              @click="deleteTableLimit(item.id)"
                              v-text="$t('delete')"
                            />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="item.table_name" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="item.limit" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </b-modal>

    <b-modal
      ref="add-table-limit-modal"
      size="lg"
      :title="$t('add_table_limit')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.stop.prevent="addTableLimitHandleSubmit">
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('table_name')"
          label-for="add-table-id"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              style="position:relative; text-align:left;"
            >
              <b-form-select
                id="add-table-id"
                v-model="partnerTableLimit.formData.tableId"
                :options="partnerTableLimit.options.tableList"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('limit')"
          label-for="add-table-limit"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="add-table-limit"
                v-model="partnerTableLimit.formData.limit"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('limit')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="addTableLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addTableLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <b-modal
      ref="edit-table-limit-modal"
      size="lg"
      :title="$t('edit_table_limit')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.stop.prevent="editTableLimitHandleSubmit">
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('limit')"
          label-for="edit-table-limit"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="edit-table-limit"
                v-model="partnerTableLimit.formData.limit"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('limit')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editTableLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editTableLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardVertical from '@/components/statistics-cards/StatisticCardVertical.vue'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'
import Ripple from 'vue-ripple-directive'

import 'vue-good-table/dist/vue-good-table.css'

import {
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormCheckbox,
  BFormInput,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import Game from '@/models/Game'
import Log from '@/models/Log'
import Transaction from '@/models/Transaction'
import TableLimit from '@/models/TableLimit'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'Accounts',
  components: {
    CustomPagination,
    StatisticCardVertical,
    AgentOverview,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInput,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      displayOperation: false,
      loading: true,
      filter: {
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      accounts: [],
      perPage: 10,
      totalItems: 0,
      nickNameRemaining: 20,
      maxNickNameCount: 20,
      maxMemoCount: 180,
      memoRemaining: 180,
      agent: {
        id: '',
        username: '',
      },
      agentLines: [],
      accountType: [],
      seamlessType: [
        {
          text: 'Transafer',
          value: '',
        },
        {
          text: 'Half',
          value: 'half',
        },
        {
          text: 'Full',
          value: 'full',
        },
      ],
      agentTitle: '',
      formData: {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        person_in_charge: '',
        email_address: '',
        white_list_ips: '',
        telegram_id: '',
        rate: '',
        memo: '',
        logout_url: '',
        telephone_number: '',
        account_type: '',
        parent_id: 0,
        contact_number: '',
        api_key: '',
        can_create_agent: 'No',
        can_multi_bet: false,
        seamless_type: '',
        callback_url: '',
        callback_balance: '',
        callback_bets: '',
        callback_results: '',
        callback_cancel: '',
        callback_jackpot: '',
      },
      memberLimitFormData: {
        loading: false,
        memberId: '',
        partnerId: '',
        player_banker_min: 0,
        player_banker_max: 0,
        tie_min: 0,
        tie_max: 0,
        p_pair_and_b_pair_min: 0,
        p_pair_and_b_pair_max: 0,
      },
      partnerLimitFormData: {
        loading: false,
        partnerId: '',
        player_banker_min: 0,
        player_banker_max: 0,
        tie_min: 0,
        tie_max: 0,
        p_pair_and_b_pair_min: 0,
        p_pair_and_b_pair_max: 0,
      },
      selectedChildAgent: {
        id: null,
        account: '',
      },
      transferFormTitle: '',
      transferFormData: {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        max_amount: 0,
        memo: '',
        transaction_type: '',
        role_from: '',
        role_to: '',
      },
      logs: {
        loading: false,
        perPage: 10,
        totalItems: 0,
        filter: {
          dateFrom: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
          dateTo: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
          timeFrom: '00:00:00',
          timeTo: '23:59:59',
          operatorAccount: '',
          page: 1,
        },
        records: [],
      },
      transactionList: {
        loading: false,
        perPage: 10,
        totalItems: 0,
        filter: {
          page: 0,
          memberId: '',
          type: 'deposit,withdrawal',
        },
        records: [],
      },
      meta: {
        report: {
          memberTotalBalance: 0,
        },
      },
      canCreateAgentOptions: [
        {
          text: 'Yes',
          value: 'Yes',
        },
        {
          text: 'No',
          value: 'No',
        },
      ],
      partnerTableLimit: {
        loading: false,
        items: [],
        options: {
          tableList: [],
        },
        formData: {
          loading: false,
          partnerId: '',
          id: '',
          tableId: '',
          limit: 500000,
        },
      },
    }
  },
  mounted() {
    this.loadData()
    if (this.userData.is_sub_account === 0 || (this.userData.is_sub_account === 1 && this.userData.authority === 'Edit')) {
      this.displayOperation = true
    }
  },
  methods: {
    stringFormatter(str) {
      if (!str) return ''
      return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },
    numberFormatter(amount) {
      const formatNumber = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0, // Prevent decimals
      })
      return formatNumber.format(amount)
    },
    loadAccounts(id) {
      this.filter.agent = id
      this.filter.account = ''
      this.loadData(1)
    },
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.list(searchParams)
      this.accounts = response.data.accounts
      this.totalItems = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.meta = response.data.meta
      this.agent = response.data.meta.agent
      this.agentLines = response.data.meta.agent_lines
      this.accountType = response.data.meta.options.account_types

      if (this.agent.role === 'admin') {
        this.agentTitle = this.$t('companies')
      } else if (this.agent.role === 'company' || this.agent.role === 'agent') {
        this.agentTitle = `${this.$t('agents')} / ${this.$t('partners')}`
      } else if (this.agent.role === 'partner') {
        this.agentTitle = this.$t('members')
      }

      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
    createAccountModal(type) {
      this.resetFormData()
      this.formData.account_type = type
      this.$refs['create-account-modal'].show()
      setTimeout(() => {
        this.formData.password = ''
      }, 1000)
    },
    createAccountHandleCancel() {
      this.$refs['create-account-modal'].hide()
    },
    getNickNameRemainingCount() {
      if (this.formData.nickname) {
        this.nickNameRemaining = this.maxNickNameCount - this.formData.nickname.length
      } else {
        this.nickNameRemaining = this.maxNickNameCount
      }
    },
    async createAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.store(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.filter.account = ''
            await this.loadData(1)
            this.$refs['create-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              if (this.formData.account_type !== null) {
                document.getElementById(response.data.results.field_id).focus()
              }
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    resetFormData() {
      this.formData = {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        person_in_charge: '',
        email_address: '',
        white_list_ips: '',
        telegram_id: '',
        rate: '',
        memo: '',
        logout_url: '',
        telephone_number: '',
        account_type: '',
        parent_id: this.agent.id,
        contact_number: '',
        api_key: '',
        can_create_agent: 'No',
        can_multi_bet: false,
        seamless_type: '',
        callback_url: '',
        callback_balance: '',
        callback_bets: '',
        callback_results: '',
        callback_cancel: '',
        callback_jackpot: '',
      }
      this.getNickNameRemainingCount()
      // if (this.accountType.length > 0) {
      //   this.formData.account_type = this.accountType[0].value
      // }
    },
    async showOperation(data) {
      this.selectedChildAgent = data
      this.$refs['operation-modal'].show()
    },
    operationHandleCancel() {
      this.$refs['operation-modal'].hide()
    },
    showTransfer(data, transactionType, title) {
      this.resetTransferFormData()
      this.transferFormTitle = title
      this.transferFormData.transaction_type = transactionType
      if (transactionType === 'transfer_to_child') {
        this.transferFormData.max_amount = this.agent.balance
        this.transferFormData.role_from = this.agent.role
        this.transferFormData.role_to = data.account_type
        if (this.agent.role === 'admin' || this.agent.role === 'company' || this.agent.role === 'agent') {
          this.transferFormData.transfer_from = this.agent.id
          this.transferFormData.transfer_to = data.id
        } else if (this.agent.role === 'partner') {
          this.transferFormData.transfer_from = this.agent.id
          this.transferFormData.transfer_to = data.memberId
        }
        this.max_amount = this.agent.balance
      } else {
        this.transferFormData.max_amount = data.balance
        this.transferFormData.role_from = data.account_type
        this.transferFormData.role_to = this.agent.role
        if (data.account_type === 'company' || data.account_type === 'agent' || data.account_type === 'partner') {
          this.transferFormData.transfer_from = data.id
          this.transferFormData.transfer_to = this.agent.id
        } else if (data.account_type === 'member') {
          this.transferFormData.transfer_from = data.memberId
          this.transferFormData.transfer_to = this.agent.id
        }
        this.max_amount = data.balance
      }
      this.selectedChildAgent = data
      this.$refs['transfer-modal'].show()
    },
    transferHandleCancel() {
      this.$refs['transfer-modal'].hide()
    },
    resetTransferFormData() {
      this.transferFormData = {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        memo: '',
        transaction_type: '',
      }
    },
    async transferHandleSubmit() {
      try {
        if (this.transferFormData.loading === false) {
          this.transferFormData.loading = true
          const response = await Transaction.transfer(this.transferFormData)
          this.transferFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['transfer-modal'].hide()
            this.selectedChildAgent.balance = response.data.results.custom.transaction.balance
            this.resetTransferFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.transferFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    getMemoRemainingCount() {
      if (this.transferFormData.memo) {
        this.memoRemaining = this.maxMemoCount - this.transferFormData.memo.length
      } else {
        this.memoRemaining = this.maxMemoCount
      }
    },
    changeAmount() {
      let result = 0
      let balance = 0

      if (this.transferFormData.transaction_type === 'transfer_to_child') {
        balance = this.agent.balance
      } else {
        balance = this.selectedChildAgent.balance
      }
      if (Number(String(this.transferFormData.amount).replace(/\D/g, '')) > balance) {
        result = String(balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
      } else {
        result = String(this.transferFormData.amount).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      this.transferFormData.amount = result
    },
    showLogs() {
      this.$refs['logs-modal'].show()
      this.logs.filter.relatedAccount = this.selectedChildAgent.account
      this.logs.filter.dateFrom = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`
      this.logs.filter.dateTo = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`
      this.logs.filter.timeFrom = '00:00:00'
      this.logs.filter.timeTo = '23:59:59'
      this.loadLogData()
    },
    async changeLogItemPerPage(itemPerPage) {
      this.logs.perPage = itemPerPage
      this.loadLogData(1)
    },
    async loadLogData(page = 1) {
      this.logs.loading = true
      const searchParams = {}
      this.logs.filter.page = page
      this.logs.filter.perPage = this.logs.perPage
      Object.keys(this.logs.filter).forEach(e => {
        if (typeof this.logs.filter[e] === 'number') {
          if (this.logs.filter[e] > 0) {
            searchParams[e] = this.logs.filter[e]
          }
        }
        if (typeof this.logs.filter[e] === 'object') {
          if (this.logs.filter[e].length > 0) {
            let newData = ''
            Object.keys(this.logs.filter[e]).forEach(d => {
              newData += `${this.logs.filter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.logs.filter[e] === 'string') {
          if (this.logs.filter[e] !== null && this.logs.filter[e].length > 0) {
            searchParams[e] = this.logs.filter[e]
          }
        }
      })
      const response = await Log.list(searchParams)
      this.logs.records = response.data.logs
      this.logs.totalItems = response.data.meta.pagination.total
      this.logs.perPage = response.data.meta.pagination.per_page
      this.logs.loading = false
    },
    async editAccount(id) {
      this.resetFormData()
      const response = await Account.edit({ id })
      this.formData = response.data
      this.formData.loading = false
      this.getNickNameRemainingCount()
      this.$refs['edit-account-modal'].show()
      setTimeout(() => {
        this.formData.password = ''
      }, 1000)
    },
    editAccountHandleCancel() {
      this.$refs['edit-account-modal'].hide()
    },
    async editAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.update(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.selectedChildAgent.account = response.data.results.custom.account.username
            this.selectedChildAgent.balance = response.data.results.custom.account.balance
            this.selectedChildAgent.share = response.data.results.custom.account.share
            this.selectedChildAgent.commission = response.data.results.custom.account.commission
            this.$refs['edit-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    partnerLimitResetFormData() {
      this.partnerLimitFormData.loading = false
      this.partnerLimitFormData.partnerId = ''
      this.partnerLimitFormData.player_banker_min = 0
      this.partnerLimitFormData.player_banker_max = 0
      this.partnerLimitFormData.p_pair_and_b_pair_min = 0
      this.partnerLimitFormData.p_pair_and_b_pair_max = 0
      this.partnerLimitFormData.tie_min = 0
      this.partnerLimitFormData.tie_max = 0
    },
    async editPartnerLimit(id) {
      this.partnerLimitResetFormData()
      const response = await Account.edit({ id })
      this.partnerLimitFormData.loading = false
      this.partnerLimitFormData.partnerId = response.data.api_id
      this.partnerLimitFormData.player_banker_min = response.data.partnerLimit.player_banker_min
      this.partnerLimitFormData.player_banker_max = response.data.partnerLimit.player_banker_max
      this.partnerLimitFormData.p_pair_and_b_pair_min = response.data.partnerLimit.p_pair_and_b_pair_min
      this.partnerLimitFormData.p_pair_and_b_pair_max = response.data.partnerLimit.p_pair_and_b_pair_max
      this.partnerLimitFormData.tie_min = response.data.partnerLimit.tie_min
      this.partnerLimitFormData.tie_max = response.data.partnerLimit.tie_max
      this.getNickNameRemainingCount()
      this.$refs['edit-partner-limit-modal'].show()
    },
    editPartnerLimitHandleCancel() {
      this.$refs['edit-partner-limit-modal'].hide()
    },
    async editPartnerLimitHandleSubmit() {
      try {
        if (this.partnerLimitFormData.loading === false) {
          this.partnerLimitFormData.loading = true
          const response = await Account.updatePartnerLimit(this.partnerLimitFormData)
          this.partnerLimitFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['edit-partner-limit-modal'].hide()
            this.partnerLimitResetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.partnerLimitFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    memberLimitResetFormData() {
      this.memberLimitFormData.loading = false
      this.memberLimitFormData.partnerId = ''
      this.memberLimitFormData.memberId = ''
      this.memberLimitFormData.player_banker_min = 0
      this.memberLimitFormData.player_banker_max = 0
      this.memberLimitFormData.p_pair_and_b_pair_min = 0
      this.memberLimitFormData.p_pair_and_b_pair_max = 0
      this.memberLimitFormData.tie_min = 0
      this.memberLimitFormData.tie_max = 0
    },
    async editMemberLimit(data) {
      this.memberLimitFormData.partnerId = data.partnerId
      this.memberLimitFormData.memberId = data.memberId
      this.memberLimitFormData.player_banker_min = data.memberLimit.player_banker_min
      this.memberLimitFormData.player_banker_max = data.memberLimit.player_banker_max
      this.memberLimitFormData.p_pair_and_b_pair_min = data.memberLimit.p_pair_and_b_pair_min
      this.memberLimitFormData.p_pair_and_b_pair_max = data.memberLimit.p_pair_and_b_pair_max
      this.memberLimitFormData.tie_min = data.memberLimit.tie_min
      this.memberLimitFormData.tie_max = data.memberLimit.tie_max
      this.$refs['edit-member-limit-modal'].show()
    },
    editMemberLimitHandleCancel() {
      this.$refs['edit-member-limit-modal'].hide()
    },
    async editMemberLimitHandleSubmit() {
      try {
        if (this.memberLimitFormData.loading === false) {
          this.memberLimitFormData.loading = true
          const response = await Account.updateMemberLimit(this.memberLimitFormData)
          this.memberLimitFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs['edit-member-limit-modal'].hide()
            this.selectedChildAgent.memberLimit.player_banker_min = this.memberLimitFormData.player_banker_min
            this.selectedChildAgent.memberLimit.player_banker_max = this.memberLimitFormData.player_banker_max
            this.selectedChildAgent.memberLimit.p_pair_and_b_pair_min = this.memberLimitFormData.p_pair_and_b_pair_min
            this.selectedChildAgent.memberLimit.p_pair_and_b_pair_max = this.memberLimitFormData.p_pair_and_b_pair_max
            this.selectedChildAgent.memberLimit.tie_min = this.memberLimitFormData.tie_min
            this.selectedChildAgent.memberLimit.tie_max = this.memberLimitFormData.tie_max
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.memberLimitFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    viewTransactionList(memberId) {
      this.transactionList.filter.memberId = memberId
      this.$refs['view-transaction-list-modal'].show()
      this.loadTransactionList(1)
    },
    async changeTransactionItemPerPage(itemPerPage) {
      this.transactionList.perPage = itemPerPage
      this.loadTransactionList(1)
    },
    async loadTransactionList(page) {
      this.transactionList.loading = true
      const searchParams = {}
      this.transactionList.filter.page = page
      this.transactionList.filter.perPage = this.transactionList.perPage
      Object.keys(this.transactionList.filter).forEach(e => {
        if (typeof this.transactionList.filter[e] === 'number') {
          if (this.transactionList.filter[e] > 0) {
            searchParams[e] = this.transactionList.filter[e]
          }
        } else if (typeof this.transactionList.filter[e] === 'string') {
          if (this.transactionList.filter[e] !== null && this.transactionList.filter[e].length > 0) {
            searchParams[e] = this.transactionList.filter[e]
          }
        }
      })
      const response = await Game.transactions(searchParams)
      this.transactionList.records = response.data.data
      this.transactionList.totalItems = response.data.meta.pagination.total
      this.transactionList.perPage = response.data.meta.pagination.per_page
      this.transactionList.loading = false
    },
    async changeStatus(status, data) {
      await Account.changeStatus({ id: data.id, status })
    },
    showPartnerTableLimit() {
      this.$refs['partner-table-limit-modal'].show()
      this.partnerTableLimit.formData.partnerId = this.selectedChildAgent.api_id
      this.loadTableLimitData()
    },
    async loadTableLimitData() {
      this.partnerTableLimit.loading = true
      const response = await TableLimit.list({ partnerId: this.partnerTableLimit.formData.partnerId })
      if (response.data.status) {
        this.partnerTableLimit.items = response.data.data
        this.partnerTableLimit.options.tableList = response.data.meta.tableListOptions
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      this.partnerTableLimit.loading = false
    },
    async deleteTableLimit(limitId) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await TableLimit.delete(limitId, { partnerId: this.selectedChildAgent.api_id })
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadTableLimitData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
          }
        }
      })
    },
    resetTableLimitFormData() {
      this.partnerTableLimit.formData.id = ''
      this.partnerTableLimit.formData.tableId = ''
      this.partnerTableLimit.formData.limit = 500000
    },
    addTableLimitModal() {
      this.resetTableLimitFormData()
      this.$refs['add-table-limit-modal'].show()
    },
    addTableLimitHandleCancel() {
      this.$refs['add-table-limit-modal'].hide()
    },
    async addTableLimitHandleSubmit() {
      try {
        if (this.partnerTableLimit.formData.loading === false) {
          this.partnerTableLimit.formData.loading = true
          const response = await TableLimit.store(this.partnerTableLimit.formData)
          this.partnerTableLimit.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadTableLimitData()
            this.$refs['add-table-limit-modal'].hide()
            this.resetTableLimitFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.partnerTableLimit.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    editTableLimitModal(item) {
      this.resetTableLimitFormData()
      this.partnerTableLimit.formData.id = item.id
      this.partnerTableLimit.formData.limit = item.limit
      this.$refs['edit-table-limit-modal'].show()
    },
    editTableLimitHandleCancel() {
      this.$refs['edit-table-limit-modal'].hide()
    },
    async editTableLimitHandleSubmit() {
      try {
        if (this.partnerTableLimit.formData.loading === false) {
          this.partnerTableLimit.formData.loading = true
          const response = await TableLimit.update(this.partnerTableLimit.formData)
          this.partnerTableLimit.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadTableLimitData()
            this.$refs['edit-table-limit-modal'].hide()
            this.resetTableLimitFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.partnerTableLimit.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>

<style>

</style>
