<template>
  <b-card class="text-center">
    <b-avatar
      v-if="showIcon === 'true'"
      class="mb-1"
      :variant="`light-${color}`"
      size="45"
    >
      <feather-icon
        size="21"
        :icon="icon"
      />
    </b-avatar>
    <div class="truncate">
      <h5 class="mb-25 font-weight-bolder">
        {{ statisticTitle }}
      </h5>
      <span>
        {{ statistic }}
      </span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    showIcon: {
      type: String,
      default: 'true',
    },
    icon: {
      type: String,
      default: 'activity',
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
